.text {
  margin-bottom: 8px;
}

.secondary {
  color: var(--color-secondary-text)
}

.bold {
  font-weight: 500;
}

.button {
  :global(.wz-button) {
    padding: 5px;
  }
}

.textArea {
  :global(.wz-input.wz-input-textarea) {
    min-height: 500px;
  }
}
